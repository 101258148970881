:root{
    --buttonRes: 20px;
    --h4font:1.68125rem;
    --mediumRes: 1rem;
    --smallRes: 0.9rem;

}

@media (max-width: 700px) {
    .whatsapp-icon {
        margin-top: 10px;
    }

    .whatsapp_float {
        width: 50px;
        height: 50px;
        bottom: 40px;
        right: 10px;
        font-size: 32px;
    }
    span.nagishli-trigger-icon{
        width: 40px;
        height: 40px;
}
span.nagishli-trigger-icon img{
    width: 23px;
    height: 23px;
    
        
}
    #NagishLiTrigger {
        overflow-x: visible;
        right: 87svw !important ;
        top: 90svh;   
    }
    .header h1{
        font-size: 2rem;
        padding-right: 5%;
        padding-left: 5%;
    }
   
    .header p{
        font-size: 19px ;
        padding-right: 5%;
        padding-left: 5%;
    }
    .imgContainer{
        margin-left: auto;
        margin-right: auto;
    }
    .imgContainer .profile{
        min-height: 10rem;
        max-width: 12rem;
    }
    .imgContainer .logo{
        min-height: 1rem;
        max-width: 12rem;
    }
    .header .buttons{
        padding-top: 20%;
        flex-direction: column ;
        min-height: 20svh;
        max-width: 90svw;
        }
        .header .buttons .to{
            min-height: 100px;
            max-width: 100%;
            
        }
        .header .buttons button span{
            font-size: var(--buttonRes);
        }
        .header .buttons .toHow{
            min-height: 100px;
            margin-top: 50px;
        }
        .header .buttons span{
            font-size: var(--buttonRes);
        }
        .profile1{
            padding-right: 0%;
        }
        .body .me h3{
            font-size: var(--buttonFont);
        }
        .body .me h4{
            font-size: larger;
            font-weight: bold;
            padding-left:3% ;
            padding-right: 5%;
        }
        .body .me .pfirst{
            padding-left: 2%;
            padding-right: 5%;
            font-size: var(--mediumRes);
        
        }
        .body .me .arrange{
            flex-wrap: wrap;
            flex-direction: column;
            line-height: 1.5rem;
            padding-left: 2%;
            padding-right: 8%;
        }
       
        .body .me .arrange1{
            margin-right: auto;
            margin-left: auto;
            width: 70%;
            font-size: var(--smallRes);
        }
        .body .me .mebottom{
            font-size: var(--mediumRes);
            padding-left: 15%;
            padding-right: 32%;
            
        }
        .body .how{
            padding-top: 15%;
            font-size: var(--buttonFont);
        }
        .Container{
       padding-right:16% ;
       padding-left:13% ;
        }
        .services-list > div > p{
            font-size: 20px ;
            
        }
     
        .body .formb{
           margin-right: 5%;
     margin-left: 5%;
        }
    
        .body .formb h3{
            padding-right: 17%;
            padding-left: 7%;
            font-size: 1.5rem;
            right:8vw;
            width: 10em;
        }
        .body .formb h4{
            padding-right: 6%;
            padding-left: 2%;
            font-size: 1rem;
        
        }
        
        .body .formb form{
            margin-right: 0%;
        
        }
        
        .body .formb form .specific {
            min-height: 350px;
            max-width: 15rem;
            
        }
        
        .body .formb form .specific input{
            outline: none;
            border-top: none;
            border-right: none;
            
        }
        
         .faq-container {
            max-width: 90%;
            padding-right: 5%;
            padding-left: 4%;
         }
         .faq-item{
            font-size: var(--mediumRes);
         }  

         .developed{
            padding-right: 14% ;
            padding-left: 5%;
         }
     
      
          }



          
          @media (min-width: 700px) and (max-width: 1400px){
            .services-list > div > p{
                font-size: large !important;
            }
            #NagishLiTrigger {
                right: 93svw !important ;
                }
                
            }