@import url("https://fonts.googleapis.com/css?family=Open+Sans:300");
@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import "~boxicons/css/boxicons.min.css";

:root{
  --deepBlue:#081B29;
  --tchelet: #00EEFF;
 --purple:#942192;
 --deePurple: #551A8B;
 --white:#FFFFFF;
 --mediumFont: 1.875rem;
 --largeFont: 3.75rem;
 --buttonFont: 2.5rem;
}
*{
    direction: rtl;
}

.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}
html{
    scroll-behavior: smooth;
}
#NagishLiTrigger {
  right: 95.6vw !important ;
  top: 10vh;}
.content-wrapper{
    background-color: var(--deepBlue);
    overflow-x: hidden;
    overflow-y: hidden;
  
}
.header{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


h1{
    
        color: var(--tchelet);
    text-align: center;
    font-family: "Open Sans";
    font-size: var(--largeFont);
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    
    }
    .header p{
        padding: 5%;
        color: var(--white);
    text-align: center;
    font-family: "Open Sans-CondensedLight", Helvetica;
    font-size: var(--mediumFont);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    }
    .imgContainer{
        padding: 5%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-around;
        
    }
    .imgContainer > *{
        flex-shrink: 1;
        flex-grow: 0;
    }
    .imgContainer .logo{
        min-height: 20%;
        max-width: 30%;
    }
    .imgContainer .profile{
        min-height: 20%;
        max-width: 30%;
    }
    .header .buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        flex-shrink: 1;
        padding: 5%;
      }
      
      .header .buttons a {
        flex: 1;
        display: flex;
        justify-content: center;
        text-decoration: none;
      }
      
      .header .buttons button {
        flex: 1;
        min-height: 148px;
        max-width: 514px;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 11.34px;
        box-shadow: 0px 0px 9.2px #551a8b, 0px 0px 18.41px #551a8b, 0px 0px 64.43px #551a8b, 0px 0px 128.87px #551a8b,
                    0px 0px 220.91px #551a8b, 0px 0px 386.6px #551a8b;
        border: none;
        cursor: pointer;
      }
      
      .header .buttons .to {
        background: var(--deePurple);
      }
      
      .header .buttons .toHow {
        background-color: var(--deepBlue);
      }
      .header .buttons button span  {
        text-decoration: none;
          color: var(--tchelet);
          background-color: transparent;
      text-align: center;
      font-family: "Open Sans-CondensedLight", Helvetica;
      font-size: var(--buttonFont);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      }
      .header .profile1{
        max-width: 514px;
        min-height: 148px;
        display: block;
  margin-left: auto;
  margin-right: auto;
      }
      .body .me{
        background-color: var(--tchelet);
    
      }
      .body h3{
        color: var(--deePurple);
        background-color: transparent;
    text-align: center;
    font-family: "Open Sans";
    font-size: var(--largeFont);
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    
    }
    
    .body h4{
        color: var(--deepBlue);
    font-family: "Open Sans";
    font-size: var(--buttonFont);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
padding-top: 5%;    
text-align: center;

    }
    .body .me .pfirst{
      color: var(--deepBlue);
      font-size: var(--mediumFont);
      font-family: "Open Sans Hebrew Condensed-Regular", Helvetica;
      padding-top: 5%;    
text-align: center;
    }
    .body .me .pfirst span{
      color: var(--purple);
      font-weight: bold;
    }
    .body .me .arrange{
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      line-height: 2.1rem;
      padding-top: 5%;    
      text-align: start;
      padding-bottom:5% ;
    }
    .body .me .arrange1 span{
      color: var(--purple);
      font-weight: bold;

    }    
    .body .me .arrange1{
        color: var(--deepBlue);
     font-family: "Open Sans Hebrew Condensed-Regular", Helvetica;
    font-size: var(--mediumFont);
    margin-right: auto;
    margin-left: auto;
    }
    .body .me .mebottom{
      padding-top: 10%;    
      padding-left: 10%;
      padding-right: 42%;
      color: var(--deepBlue);
      font-size: var(--mediumFont);
      font-family: "Open Sans Hebrew Condensed-Regular", Helvetica;
    
    }
    .body p span{
      color: var(--tchelet);
  }
  .body .how{
      color: var(--tchelet);
  font-family: "Open Sans-CondensedLight", Helvetica;
  font-size: var(--largeFont);
  font-weight: 300;
  padding: 5%;
  letter-spacing: 0;
  }
  .Container {
    padding: 90px;
   
  }
  
  #services {
    color: aliceblue;
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 4rem;
  }
  
  .sub-title {
    text-align: center;
    font-size: 60px;
    padding-bottom: 70px;
  }
  .sub-title > span {
    color: #00eeff;
  }
  .services-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(259px, 1fr));
    grid-gap: 40px;
    margin-top: 50px;
  }
  .services-list > div {
    background-color: transparent;
    padding: 40px;
    font-size: 13px;
    font-weight: 13px;
    border-right: 10px;
    border-radius: 20px;
    transition: background 0.5s, transform 0.5s;
    box-shadow: 1px 1px 20px #012290f7, 1px 1px 40px #0053b8f7;
  }
  .services-list > div > i {
    font-size: 50px;
    margin-bottom: 30px;
    color: var(--tchelet);
  }
  
  .services-list > div > h2 {
    font-family: "Open Sans-CondensedLight", Helvetica;
  font-size: var(--buttonFont);
  color: var(--tchelet);
  font-weight: 300;
    margin-bottom: 15px;
  }
  .services-list > div > p{
    font-family: "Open Sans-CondensedLight", Helvetica;
  font-size: var(--mediumFont);
  }
  .services-list > div > a {
    text-decoration: none;
    color: #000000;
    font-size: 12px;
    margin-top: 20px;
    display: inline-block;
  }
  .read {
    display: inline-block;
    padding: 12px 28px;
    background: #0ef;
    border-radius: 40px;
    font-size: var(--buttonFont);
    font-family: "Open Sans-CondensedLight", Helvetica;
  
    color: #081b29;
    letter-spacing: 1px;
    text-decoration: none;
    font-weight: 300;
    box-shadow: 0 0 5px #0ef, 0 0 25px #0ef;
  }
  .read1{
    display: inline-block;
    padding: 12px 28px;
    background: #0ef;
    border-radius: 40px;
    font-size: var(--buttonFont);
    font-family: "Open Sans-CondensedLight", Helvetica;
  
    color: #081b29;
    letter-spacing: 1px;
    text-decoration: none;
    font-weight: 300;
    box-shadow: 0 0 5px #0ef, 0 0 25px #0ef;
  }
  .read:hover {
    box-shadow: 0 0 5px cyan, 0 0 25px cyan, 0 0 50px cyan, 0 0 100px cyan,
      0 0 200px cyan;
  }
  .body .formb{
 margin:auto;
border: 1px solid var(--tchelet); 
   min-height: 630px;
    max-width: 600px;
 }
 .body .formb dialog{
  width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: 1;
    background-color: var(--deepBlue);
    border: 1px solid var(--tchelet);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
 }

 .body .formb dialog h3{
 
    color: var(--tchelet);
    font-size: var(--buttonFont);
 }
 .body .formb dialog button{
  background-color: var(--tchelet);
  color: var(--white);
  border: none;
  border-radius: 50%;
  max-width: 40px;
  min-height: 40px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
 }
 .body .formb h3{
    
    color: var(--tchelet);
    font-size: var(--buttonFont);
}
.body .formb dialog p{
position: absolute;
top: 40%;
right: 16%;
line-height: 100px;
font-size: var(--buttonFont);
color: var(--tchelet);
}
.body .formb h4{
   padding-right: 4%;
   padding-left: 5%;
color: var(--tchelet);
font-size: 22px;
overflow: visible; /* Allow overflow to show hover effects */

}

.body .formb form{
    padding-right: 15%;
    padding-left: 10%;
    margin-bottom: 15%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 500px;
    overflow: visible; /* Allow overflow to show hover effects */

}

.body .formb form .specific {
    min-height: 300px;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    outline: none;
    border: none;
    justify-content: space-around;
}

.body .formb form .specific input{
    outline: none;
    max-width: 100%;
    background-color: transparent;
    color: #00EEFF;
    border-top: none;
    border-left: none;
    border-bottom: 1px solid var(--tchelet);
    border-right: none;
    font-size: large;
    
}
::placeholder{
    color: var(--tchelet);
    font-size: large;
}
.body .formb form .submit{
    max-width: 90%;
    min-height: 100px;
    border-radius: 11.34px;
    background: var(--deePurple);
    box-shadow: 0px 0px 9.2px #551a8b, 0px 0px 18.41px #551a8b, 0px 0px 64.43px #551a8b, 0px 0px 128.87px #551a8b,
0px 0px 220.91px #551a8b, 0px 0px 386.6px #551a8b;
border: none;
cursor: pointer;

}

.body .formb form .submit span{
    color: var(--tchelet);
    text-align: center;
    font-family: "Open Sans-CondensedLight", Helvetica;
    font-size: var(--mediumFont);
font-style: normal;
font-weight: 400;
line-height: normal;
}

.body .faq{
  color: var(--tchelet);
  font-family: "Open Sans-CondensedLight", Helvetica;
  font-size: var(--largeFont);
  font-weight: 300;
padding: 5%;
padding-right: 0%;
  letter-spacing: 0;
}

.faq-container {
  width: 600px;
  font-family: "Open Sans-CondensedLight", Helvetica;
  color: #00EEFF;
margin: auto;
}

.faq-item {
  margin: 10px 0;
  border: 1px solid #551A8B;
  border-radius: 5px;
  overflow: visible;
}

.faq-question {
  padding: 10px;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: background-color 0.3s;
}

.faq-question:hover {
  background-color: #333;
}

.faq-answer {
  padding: 10px;
  background-color: #000;
  border-top: 1px solid #551A8B;
  color: white;
}

footer {
 padding-top:5% ;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.contact-icons a i {
  text-decoration: none;
}

.contact-icons i {
  
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: transparent;
  border: 2px solid #0ef;
  border-radius: 50%;
  font-size: 20px;
  color: #0ef;
  text-decoration: none;
  
}
.contact-icons i:hover {
  background: #0ef;
  color: #000;
  box-shadow: 0 0 20px #0ef;
}
.information{

  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.information span{
  color: var(--white);
  height: 5vh;

}
.information span i{
  color: var(--tchelet);
}

 .developed{
  font-family: "Open Sans-CondensedLight", Helvetica;
padding-right: 40%;
  color: var(--white);
  text-decoration: none;
}
